import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'

//import App from './App'
import reportWebVitals from './reportWebVitals'

import './styles/styles.scss'
import { SpinnerFullHeight } from './components/spinner/SpinnerFullHeight'
const App = React.lazy(() => import('./App'))

//@ts-ignore
if (process.env.NODE_ENV !== 'production') {
  //@ts-ignore
  import(`./styles/size-debug.scss`).then(() => {})
}

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
      <App />
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
